
import { defineComponent } from 'vue';
import globals from '../../helpers/globals';
import FormButton from '../../components/forms/Button.vue';
import SimulationDataService from '../../services/SimulationDataService';
import DocumentDataService from '../../services/DocumentDataService';
import ModalData from '../../types/ModalData';
import SimulationDataHelper from '../../helpers/SimulationDataHelper';
import FileDataService from '../../services/FileDataService';

export default defineComponent({
  name: 'Result',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
  },
  created() {
    const vm = this;

    /* Try to set profile if authenticated */
    this.setProfile(this.$store);

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      vm.$store.dispatch('setSelectedSimulation', data);

      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleSideBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      SimulationDataHelper.prepareComponentResult(
        this.$store,
        this.$route.params.simulation as string,
        callback,
      );
    },
    download() {
      this.$store.dispatch('isLoading', true);

      SimulationDataService.download(this.$route.params.simulation as string)
        .then((response: any) => {
          const saveData = (function () {
            const a = document.createElement('a');
            document.body.appendChild(a);

            return function (data: any, fileName: string) {
              const blob = new Blob([data], { type: 'application/pdf' });
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
            };
          }());

          const now = new Date();

          this.$store.dispatch('isLoading', false);

          saveData(response.data, `simulation-bna-${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}.pdf`);
        })
        .catch((e: Error) => { /* */ });
    },
    modalShow() {
      const vm = this;

      this.$store.dispatch('isLoading', true);

      DocumentDataService.getFiltered(this.$store.state.selected.segment, this.$store.state.selected.product)
        .then((response: any) => {
          vm.$store.dispatch('setDocumentTypes', response.data.data);

          vm.$store.dispatch('modal', {
            id: 'documents-available-modal',
            title: 'Important !',
            description: vm.modalDescription(),
            checkbox: {
              placeholder: 'Je confirme la disponibilité de ces documents.',
            },
            actions: {
              cancel: {
                label: 'Non, pas maintenant',
                callback: () => { /* */ },
              },
              proceed: {
                label: 'Oui, c\'est bon',
                callback: () => {
                  vm.setStepAsCompleted(this, true);

                  vm.initLocalStorage();

                  const token = this.getAccessToken();

                  FileDataService.create(token, vm.$route.params.simulation as string, {
                    failRedirect: 'Register',
                  }).then((responses: any) => {
                    vm.$store.dispatch('setSelectedFile', responses.data.data);
                  });
                },
              },
            },
          } as ModalData);

          this.$store.dispatch('isLoading', false);
        })
        .catch((e: Error) => { /* */ });
    },
    modalDescription() {
      const icon = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" class="h-4 w-4 inline-block mr-2 -mt-1" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve"> <style type="text/css"> .st0{fill:#0054FF;} .st1{fill:#FFFFFF;} </style> <circle class="st0" cx="16" cy="15.9" r="15.8"></circle> <polygon class="st1" points="23,9 13.2,18.8 9,14.5 6.4,17 10.7,21.3 13.2,23.8 15.8,21.3 25.6,11.5 "></polygon> </svg>';

      const text = `
        Assurez-vous d'avoir à votre portée les documents suivants afin de les inclure dans votre demande de financement :
        <br>
      `;

      let block = '';
      Object.keys(this.$store.state.documentTypes).forEach((key: any) => {
        block = block.concat(`<div class="mt-2 font-medium text-left">${icon}${this.$store.state.documentTypes[key].name}</div>`);
      });

      return `${text}<div class="flex flex-col mx-auto mt-4 w-4/5 2xl:w-3/5">${block}</div>`;
    },
    ftsModalShow() {
      const vm = this;

      this.$store.dispatch('isLoading', true);

      DocumentDataService.getFiltered(this.$store.state.selected.segment, this.$store.state.selected.product)
        .then((response: any) => {
          vm.$store.dispatch('setDocumentTypes', response.data);

          vm.$store.dispatch('modal', {
            id: 'simulation-repeat-modal',
            title: '<div class="font-semibold text-gray-600 text-center font-BasierCircle"><?xml version="1.0" ?><svg data-name="Layer 1" class="inline -mt-2" id="Layer_1"  width="34px" height="34px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:#efcc00;}.cls-2{fill:#353535;}</style></defs><title/><path class="cls-1" d="M30.16,11.51,6.84,51.9a2.13,2.13,0,0,0,1.84,3.19H55.32a2.13,2.13,0,0,0,1.84-3.19L33.84,11.51A2.13,2.13,0,0,0,30.16,11.51Z"/><path class="cls-2" d="M29,46a3,3,0,1,1,3,3A2.88,2.88,0,0,1,29,46Zm1.09-4.66-.76-15h5.26l-.73,15Z"/></svg> <p class="inline"> Attention </p></div>',
            description: vm.modalHomeDescription(),
            actions: {
              cancel: {
                label: 'Quitter la page',
                callback: () => {
                  vm.setStepAsCompleted(this, true);

                  vm.initLocalStorage();

                  vm.$router.push({
                    name: 'FinanceTypeSelection',
                    params: {
                      segment: vm.$store.state.selected.segment.pid,
                    },
                  });
                },
              },
              proceed: {
                label: 'Rester sur la page',
                callback: () => { /*  */ },
              },
            },
          } as ModalData);

          this.$store.dispatch('isLoading', false);
        })
        .catch((e: Error) => { /* */ });
    },
    modalHomeDescription() {
      const text = `
        <p class="text-gray-600  text-base font-semibold text-center font-BasierCircle mt-2">Voulez-vous vraiment quitter cette page ? </p><br>
       `;

      return `${text}<div class="font-BasierCircle text-center">Les données saisies de votre simulation pourraient ne pas être enregistrées.</div>`;
    },
  },
});
